<template>
	<div class="QY_form">
		<div class="form_center">
			<img src="../../assets/img/welimg.png" class="login_logo" alt="">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
				<el-form-item :label="$t('EMAILPHONE')" prop="account">
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.account"></el-input> -->
						<div>{{ruleForm.account}}</div>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('task.MM')" prop="password">
					<el-input v-model="ruleForm.password" show-password :placeholder="$t('PWDRULE')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('QDMM')" prop="repassword">
					<el-input v-model="ruleForm.repassword" show-password placeholder=""></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('QY_msg.QYMC')" prop="business_name">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.business_name"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>	
				<el-form-item :label="$t('my_msg.LXFS')" prop="contact_info">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.contact_info"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.YZBM')" prop="postcode">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.postcode"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.DDFX')" prop="cityname">
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.name"></el-input> -->
						<!-- <template v-if="lang=='zh'">
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'name',value:'name'}" :placeholder="$t('QXZ')"></el-cascader>
						</template>
						<template v-else> -->
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'label',value:'label'}" :placeholder="$t('QXZ')"></el-cascader>
						<!-- </template> -->
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="$t('QY_msg.DWDZ')" prop="location">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.location">
							
						</el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;">{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item> -->
				<el-form-item :label="$t('QY_msg.XQDZ')" prop="address">
					<div style="display: flex;align-items: center;">
						<el-input type="textarea" v-model="ruleForm.address" :placeholder="$t('ADRTIP')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="$t('RJYZM')" prop="captcha_code">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.captcha_code" placeholder=""></el-input>
						
						
						<div v-html="imgcode" @click="getImgcode" style="line-height: 1.2margin-left: 10px;"></div>
						<el-button type="primary" size="mini" style="margin-left: 10px;" @click="getImgcode">{{$t('HYZ')}}</el-button>
					</div>
				</el-form-item> -->
				<el-form-item label="" prop="tongyi" style="color:#567BB6;">
					<el-checkbox v-model="agree"></el-checkbox>
					<span style="color: #f00;cursor: pointer;" @click="showtiaokuan=true"><!-- 使用条款 -->{{$t('header.SYTK')}}</span>
					 <!-- 和 -->{{$t('AND')}}
					<span style="color: #f00;cursor: pointer;" @click="showyinsi=true"><!-- 个人信息保护政策 -->{{$t('GRXXBHZC')}}</span> <!-- 我同意 -->{{$t('IAGREE')}}
					
				</el-form-item>
				
				
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')"><!-- 确认注册 -->{{$t('task.QDZC')}}</el-button>
					<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-dialog
		  :title="$t('header.YSZC')"
		  :visible.sync="showyinsi"
		  width="50%"
		  top="50px"
		  >
		  <div v-html="yinsi" class="yscont"></div>
		</el-dialog>
		<el-dialog
		  :title="$t('header.SYTK')"
		  :visible.sync="showtiaokuan"
		  width="50%"
		  top="50px"
		  >
		  <div v-html="tiaokuan" class="yscont"></div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showyinsi:false,
				yinsi:'',
				showtiaokuan:false,
				tiaokuan:'',
				citydata:[],
				agree:false,
				imgcode:'',
				ruleForm: {
					sign:'',
					account:'',
					password:'',
					repassword:'',
					business_name: '',
					contact_info: '',
					postcode: '',
					//country: '',
					province: '',
					city: '',
					area: '',
					location: '',
					address: '',
					cityname:''
				},
				rules: {
					account: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					password: [
						{required: true,trigger: 'blur',message:this.$t('QSR')},
						{min:6,max:16,trigger: 'blur',message: this.$t('PWDRULE')}
					],
					repassword: [
						{required: true,trigger: 'blur',message:this.$t('QSR')},
						{min:6,max:16,trigger: 'blur',message: this.$t('PWDRULE')}
					],
					business_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],					
					contact_info: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					postcode: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					location: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					address: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					captcha_code: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					cityname: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
				}
			};
		},
		created() {
			this.getCitys();
			// if(this.lang=='jp'){
			// 	this.getCitys();
			// }else{
			// 	this.citydata=require('@/assets/js/city.json'); // 直接引入json文件;
			// }
			this.ruleForm.account=this.$route.query.account;
			this.ruleForm.sign=this.$route.query.sign;
			
			
			this.getImgcode();
			this.getInfo();
		},
		methods:{
			async getInfo(){
				let res=await this.$request.get('/api/sysdict/getProtectionPolicy');
				this.yinsi=res.data;
				let res2=await this.$request.get('/api/sysdict/getTermsOfUse');
				this.tiaokuan=res2.data;
			},
			//获取省市区数据
			async getCitys(){
				let res=await this.$request.get('/api/sysdict/getJapanCityCode');
				this.citydata=res.data.records;
			},
			//选择省市区
			selectCity(val){
				console.log(val);
				this.ruleForm.province=val[0];
				this.ruleForm.city=val[1];
				if(val.length>2){
					this.ruleForm.area=val[2];
				}else{
					this.ruleForm.area='';
				}
				console.log(this.ruleForm);
				//this.$refs.ruleForm.$emit('el.form.change');
			},
			//获取图形验证码
			async getImgcode(){
				let res=await this.$request.get('/api/custauth/captcha',{height:40},{isimg:1});
				this.imgcode=res;
			},
			//提交注册
			async submitForm(){
				// console.log(this.ruleForm);
				let data=JSON.parse(JSON.stringify(this.ruleForm));
				delete data.cityname;
				//console.log(data);
				
				this.$refs['ruleForm'].validate(async (valid) => {
				  if (valid) {
					if(this.agree==false){
						this.$message({
							message:this.$t('YDTYZC'),
							type:'error'
						});
						return;
					}
					this.$request.post('/api/busauth/register',data).then((res)=>{
						this.$message({
							message:res.message,
							type:'success'
						});
						this.$local.set('t_k',res.data);
						this.$emit('login',{user_role:1});
						this.$request.reg({userid:this.ruleForm.user_id,nickname:this.ruleForm.business_name,faceurl:''});
						this.$router.push({path:'/home'});
					}).catch(()=>{
						this.getImgcode();
					});					 
				  } else {
					// this.$message({
					// 	message:this.$t('QTXWZXX'),
					// 	type:'warning'
					// });
					//return false;
				  }
				});
			}
		}
	};
</script>

<style lang="less">
	.yscont{line-height: revert;
		p,h1,h2,h3,h4,h5,h6{ margin: revert;padding:revert ;line-height: initial; }
		strong,h1,h2,h3,h4,h5,h6{font: revert;}
	}	
	.QY_form {
		// padding: 30px;
		min-height: 100%;
		background-color: #F3F1EA;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;
		.form_center {
			width: 874px;
			padding: 30px;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.el-form{width: 640px;}
		}
		.login_logo {
			width: 389px;
			
			margin-bottom: 30px;
		}
		
	}
</style>
